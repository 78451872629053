<template>
  <modal name="upload-status" max-width="650px" @update:payload="handlePayloadUpdate" hideFooterButtons>
    <template #title>
      <h3 class="upload-status-modal__title" :class="uploadStatusClass">
        {{ uploadStatusTitle }}
      </h3>
    </template>

    <template #default="{ payload }">
      <div class="mt-6">
        <span class="color-neutral-600">Файл</span>
        {{ payload.data.fileName }}
        <span class="color-neutral-600">загружен.</span>
      </div>

      <div class="upload-status-modal__table mt-6">
        <div class="upload-status-modal__cell upload-status-modal__cell--header">
          Локация
        </div>

        <div class="upload-status-modal__cell upload-status-modal__cell--header">
          Период
        </div>

        <div class="upload-status-modal__cell upload-status-modal__cell--header">
          Загружено чеков на сумму
        </div>

        <div class="upload-status-modal__cell">
          Ленина
        </div>

        <div class="upload-status-modal__cell color-neutral-600">
          с 01.25 по 23.04
        </div>

        <div class="upload-status-modal__cell color-primary-main">
          300 000р
        </div>
      </div>
    </template>
  </modal>
</template>

<script setup>
import Modal from "@/components/Modal/Modal.vue";
import { computed, ref } from "vue";

const localPayload = ref(null);

const uploadStatusClass = computed(() =>
  localPayload.value.data.status ? "upload-status-modal__title--success" : "upload-status-modal__title--error"
);
const uploadStatusTitle = computed(() => (localPayload.value.data.status ? "Успешно!" : "Беда!"));

const handlePayloadUpdate = newPayload => {
  localPayload.value = newPayload;
};
</script>

<style scoped lang="scss">
@import "../../../styles/ui/fontSize";

.upload-status-modal {
  &__title {
    &--success {
      color: var(--color-green-dark-main);
    }

    &--error {
      color: var(--color-red-dark-main);
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--sp-3);
  }

  &__cell {
    &--header {
      @include add-font-face("body-3", var(--color-neutral-500));
    }
  }
}
</style>

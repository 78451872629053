<template>
  <div
    class="dropzone"
    :class="{ 'dropzone--dragover': isDragover }"
    @dragover.prevent="handleDragover"
    @dragleave.prevent="handleDragleave"
    @drop.prevent="handleDrop"
    @click="triggerFileInput"
  >
    <input
      type="file"
      ref="fileInput"
      class="dropzone__input"
      hidden
      :accept="acceptedFileTypes"
      @change="changeFile"
    />
    <div class="dropzone__content">
      <div v-if="selectedFile" class="dropzone__file">
        <div class="dropzone__remove-button" @click.stop="removeFile">
          <base-icon class="nav__close" width="16" height="16" screen-size-dependency :path="'Close-grey.svg'" />
        </div>
      </div>

      <p v-else class="d-flex sp-1">
        <span class="color-primary-main">Выберите файл</span>
        <span class="color-neutral-400">или перетащите его</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";

const emit = defineEmits(["file-selected", "error"]);

const { maxSize = 5 * 1024 * 1024, acceptedFileTypes = "", removedFile = false } = defineProps({
  maxSize: { type: Number },
  acceptedFileTypes: { type: String },
  removedFile: { type: Boolean },
});

const fileInput = ref(null);
const isDragover = ref(false);
const selectedFile = ref(null);

/** TODO Class logic */
const selectFile = file => {
  console.log({ file });
  if (validateFile(file)) {
    selectedFile.value = file;

    emit("file-selected", file);
  }
};

const removeFile = () => {
  selectedFile.value = null;

  emit("file-selected", null);
};

const validateFile = file => {
  if (maxSize && file.size > maxSize) {
    showError(`Файл слишком большой. Максимальный размер: ${maxSize / 1024 / 1024}MB`);

    return false;
  }

  if (acceptedFileTypes && !file.type.match(acceptedFileTypes.replace(/,/g, "|"))) {
    showError("Неподдерживаемый тип файла");

    return false;
  }

  return true;
};

const showError = errorMessage => {
  emitter.emit(EVENTS.SHOW_MODAL, {
    name: "alert",
    data: {
      errorMessage,
    },
  });
};

/** TODO UI logic */
const changeFile = event => {
  const file = event.target.files[0];

  if (file) {
    selectFile(file);
  }
};

const handleDragover = () => {
  isDragover.value = true;
};

const handleDragleave = () => {
  isDragover.value = false;
};

const handleDrop = event => {
  const file = event.dataTransfer.files[0];

  isDragover.value = false;

  if (file) {
    selectFile(file);
  }
};

const triggerFileInput = () => {
  fileInput.value.click();
};

watch(
  () => removedFile,
  () => {
    removedFile && removeFile();
  }
);
</script>

<style scoped lang="scss">
//TODO поправить стили

.dropzone {
  width: 100%;
  max-width: 500px;
  min-height: 40px;
  padding: var(--sp-ex-10);
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: border-color 0.3s;
  cursor: pointer;

  &--dragover {
    border-color: #007bff;
    background: rgba(0, 123, 255, 0.1);
  }

  &__file {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    cursor: initial;
  }

  &__remove-button {
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border-radius: 5px;
    background: white;
    cursor: pointer;
  }
}
</style>
